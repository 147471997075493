import { useState } from 'react';
import { ResetAccountPasswordRequest } from '~/types/api/resetAccountPassword';
import client from '../client';

export const useResetPassword = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const send = async (data: ResetAccountPasswordRequest): Promise<boolean> => {
    let response;
    setLoading(true);

    try {
      response = await client.post('/accounts/account/reset-password', {
        old_password: data.oldPassword,
        new_password: data.newPassword,
      });
    } catch (error: any) {
      setError(error.response.data.response.error);
    } finally {
      setLoading(false);
      return response?.status === 204;
    }
  };

  return { loading, error, send };
};
