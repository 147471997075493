import { useState, useCallback, useEffect } from 'react';
import { useResetPassword } from 'src/api/auth/useResetPassword';
import { notifications } from '@mantine/notifications';
import { modals } from '@mantine/modals';
import { ResetPasswordForm } from 'src/components/Organism/Forms/ResetPasswordForm';
import { Button } from '@mantine/core';
import { ErrorMessage } from 'src/components';

const INITIAL_STATE: any = {
  oldPassword: '',
  newPassword: '',
  confirmNewPassword: '',
};

export default function ResetAccountPassword() {
  const [values, setValues] = useState(INITIAL_STATE);
  const [validateData, setValidateData] = useState(false);
  const [dataValidated, setDataValidated] = useState(false);

  const { send, loading, error } = useResetPassword();

  const handleSetValue = (key: string, value: string | number | boolean) => {
    setValues((prevState: any) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleClick = async () => {
    if (!dataValidated) setValidateData(true);
  };

  const handleSubmit = useCallback(async () => {
    if (dataValidated) {
      setDataValidated(false);

      const success = await send(values);

      if (success) {
        modals.closeAll();
        notifications.show({
          title: 'Password Reset',
          message: 'Your have successfully updated your password.',
          color: 'teal',
        });
      }
    }
  }, [dataValidated, send, values]);

  useEffect(() => {
    handleSubmit();
  }, [dataValidated, handleSubmit]);

  return (
    <>
      <ResetPasswordForm
        values={values}
        handleSetValue={handleSetValue}
        setDataValidated={setDataValidated}
        setValidate={setValidateData}
        validate={validateData}
      />
      <Button fullWidth mb={10} loading={loading} onClick={handleClick}>
        Submit
      </Button>
      <ErrorMessage error={error} />
    </>
  );
}
