import { useEffect, useState } from 'react';
import { z } from 'zod';
import { FormProps } from './FormProps';
import { Stack, PasswordInput } from '@mantine/core';
import { Icon } from '@iconify/react';

const userSchema = z
  .object({
    oldPassword: z.string().trim().nonempty('This field is required. '),
    newPassword: z
      .string()
      .trim()
      .nonempty('This field is required. ')
      .regex(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
        'Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one digit, and one special character.'
      ),
    confirmPassword: z.string().trim().nonempty('This field is required. '),
  })
  .refine(data => data.newPassword === data.confirmPassword, {
    message: 'Passwords must match. ',
    path: ['confirmPassword'],
  });

export const ResetPasswordForm = (props: FormProps) => {
  const { validate, setValidate, values, handleSetValue, setDataValidated } =
    props;
  const [errors, setErrors] = useState<z.ZodError | null>(null);

  useEffect(() => {
    if (validate) {
      const result = userSchema.safeParse(values);

      if (result.success) {
        setDataValidated(true);
      } else {
        setErrors(result.error);
      }

      setValidate(false);
    }
  }, [setErrors, validate, setValidate, values, setDataValidated]);

  return (
    <Stack spacing="sm">
      <PasswordInput
        name="oldPassword"
        required
        label="Current password"
        placeholder="Your current password"
        mb="sm"
        mt="sm"
        icon={<Icon icon="carbon:locked" />}
        value={values.password}
        onChange={e => handleSetValue('oldPassword', e.target.value)}
        error={errors?.formErrors.fieldErrors.oldPassword}
      />
      <PasswordInput
        name="newPassword"
        required
        label="New password"
        placeholder="Your new password"
        mb="sm"
        icon={<Icon icon="carbon:locked" />}
        value={values.password}
        onChange={e => handleSetValue('newPassword', e.target.value)}
        error={errors?.formErrors.fieldErrors.newPassword}
      />
      <PasswordInput
        name="confirmPassword"
        required
        label="Confirm new password"
        placeholder="Confirm your new password"
        mb="sm"
        icon={<Icon icon="carbon:locked" />}
        value={values.confirmPassword}
        onChange={e => handleSetValue('confirmPassword', e.target.value)}
        error={errors?.formErrors.fieldErrors.confirmPassword}
      />
    </Stack>
  );
};
